// 登陆
import request from '@/utils/request2'
import { encrypt } from '@/utils/aes.js'
export function checkUserLogin(data) {
  data.pwd = encrypt(sessionStorage.getItem('token'),data.pwd)
  return request({
    url: '/api/wx/checkUserLogin',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
      lanip: window.lan_ip || '0.0.0.0',
      netip: window.net_ip || '0.0.0.0'
    },
    data
  })
}
export function checkUserIsActive(params) {
  let { username, pwd } = params
  pwd = encrypt(sessionStorage.getItem('token'), pwd)
  return request({
    url: '/api/wx/checkUserIsActive',
    method: 'get',
    params: { username, pwd }
  })
}

// 登录前提示密码是否需要修改提醒接口
export function isChangePwd(params) {
  const { username } = params
  return request(
    {
      url: '/api/login/isChangePwd',
      method: 'get',
      params: { username }
    },
    '',
    false
  )
}
// 获取手机短信验证码接口
export function getSmsCode(params) {
  return request(
    {
      url: '/api/login/getSmsCode',
      method: 'get',
      params
    },
    '',
    false
  )
}
// 根据手机号找回密码接口
export function changePwdByMobilePhone(params) {
  const { username, mobilephone, newpwd1, newpwd2 } = params
  return request(
    {
      url: '/api/login/changePwdByMobilePhone',
      method: 'get',
      params: { username, mobilephone, newpwd1, newpwd2 }
    },
    '',
    false
  )
}
// 根据手机号找回密码接口（无用户名）
export function changePwdByPhone(params) {
  const { mobilephone, newpwd1, newpwd2 } = params
  return request(
    {
      url: '/api/login/changePwdByPhone',
      method: 'get',
      params: { mobilephone, newpwd1, newpwd2 }
    },
    '',
    false
  )
}

// 根据手机号码获取员工相关信息
export function getPhoneEmpInfo(params) {
  const { mobilephone } = params
  return request(
    {
      url: '/api/login/getPhoneEmpInfo',
      method: 'get',
      params: { mobilephone }
    },
    '',
    false
  )
}

export function checkUserLoginBySmsCode (params) {
  return request({
    url: '/api/login/checkUserLoginBySmsCode',
    method: 'get',
    params: params
  })
}

// guid 单点登录
export function getUserSignOnInfo(params) {
  const { guid, wf_paramlist } = params
  return request({
    url: '/api/login/getUserSignOnInfo',
    method: 'get',
    params: { guid, wf_paramlist }
  })
}