import request from '@/utils/request2'
import { encrypt, decrypt } from '@/utils/aes.js'

// 获取token
export function getWxApiToken (data) {
  return request({
    url: '/api/login/getWxApiToken',
    method: 'get',
    params: data
  })
}

// 根据用户usertoken获取用户详细信息
export function getUserInfo (params) {
  return request({
    url: '/api/wx/getUserInfo',
    method: 'get',
    params
  })
}
export function getUserInfoByUserName (params) {
  const { username } = params
  return request({
    url: '/api/wx/getUserInfoByUserName',
    method: 'get',
    params: { username }
  })
}

// 修改密码接口
export function changeUserPwd (data) {
  return request({
    url: '/api/wx/changeUserPwd',
    method: 'get',
    params: data
  })
}

// 获取下拉列表的值
export function GetFilterDropDownList (data) {
  let wxToken = sessionStorage.getItem('token')
  data.listsql = encrypt(wxToken, data.listsql)
  return request({
    url: '/api/wx/getFilterDropDownList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 通用 >>> 根据sql语句转换成实际的值 （ps 需要判断前7位字符是`select`）
export function getSqlValue (data) {
  return request({
    url: '/api/baseform/getSqlValue',
    method: 'get',
    params: data
  })
}

// 通用 >>> //根据工号获取员工信息
export function getEmpInfoByEmpCode (data) {
  return request({
    url: '/api/baseform/getEmpInfoByEmpCode',
    method: 'get',
    params: data
  })
}

//4.	微信端获取用户openid接口
export function getOpenId (data) {
  return request({
    url: '/api/wx/getOpenId',
    method: 'get',
    params: data
  })
}

//微信端获取用户openid接口,带单点登陆信息
export function getOpenId_Json (data) {
  return request({
    url: '/api/wx/getOpenId_Json',
    method: 'get',
    params: data
  })
}

//	根据手机号码绑定openid到人才库
export function bindWxOpenIdByMobilePhone (data) {
  return request({
    url: '/api/wx/bindWxOpenIdByMobilePhone',
    method: 'get',
    params: data
  })
}
//2.	获取首页的自助查询和自助申请列表接口
export function getQueryAndReqList (data) {
  return request({
    url: '/api/wx/getQueryAndReqList',
    method: 'get',
    params: data
  })
}
// 获取语言列表接口
export function getLangList () {
  return request({
    url: '/api/login/getLangList',
    method: 'post'
  })
}
//3.获取首页顶部4个按钮的标题序号和名称信息接口
export function getWxTopBtnList (data) {
  return request({
    url: '/api/wx/getWxTopBtnList',
    method: 'get',
    params: data
  })
}

// 1.获取工作台首页的新闻列表（获取最新的10笔）
export function getTopNewsList (params) {
  return request({
    url: '/api/workbench/getTopNewsList',
    method: 'get',
    params: params
  })
}

// 获取所有历史新闻列表
export function getAllNewsList (params) {
  const { newstitle, pageindex, pagesize, userid } = params
  return request({
    url: '/api/workbench/getAllNewsList',
    method: 'get',
    params: { newstitle, pageindex, pagesize, userid }
  })
}

// 2.获取单个新闻的详细信息
export function getNewsInfo (params) {
  const { autoid, username } = params
  return request({
    url: '/api/workbench/getNewsInfo',
    method: 'get',
    params: { autoid, username }
  })
}

// 获取“我的指标”数（获取今年的360和KPI考核记录即可）
export function getMyKhCount (params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getMyKhCount',
    method: 'get',
    params: { empid }
  })
}

// 1.获取员工个人信息字段列表和字段值接口
export function getEmpPersonInfo (params) {
  const { empid } = params
  return request({
    url: '/api/wx/getEmpPersonInfo',
    method: 'get',
    params: { empid }
  })
}

// 2.保存员工个人信息接口
export function saveEmpPersonInfo (data) {
  return request({
    url: '/api/wx/saveEmpPersonInfo',
    method: 'post',
    data: data
  })
}

// 根据用户名获取短信验证码
export function getUserSmsCode (params) {
  const { username } = params
  return request({
    url: '/api/wx/getUserSmsCode',
    method: 'get',
    params: { username }
  })
}
// 获取首页未读消息数量
export function getAlarmMsgCount (params) {
  const { empid } = params
  return request({
    url: '/api/wx/getAlarmMsgCount',
    method: 'get',
    params: { empid }
  })
}
// 获取消息列表
export function getAlarmMsgList (params) {
  const { empid, isread } = params
  return request({
    url: '/api/wx/getAlarmMsgList',
    method: 'get',
    params: { empid, isread }
  })
}
// 获取消息详情
export function getAlarmMsgDetail (params) {
  const { autoid } = params
  return request({
    url: '/api/wx/getAlarmMsgDetail',
    method: 'get',
    params: { autoid }
  })
}
// 工资密码验证接口
export function checkSalaryPwd (data) {
  return request({
    url: '/api/wx/checkSalaryPwd',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}
// 获取指定范围内的工资签收记录列表接口（按月份或为空返回所有）
export function getEmpMonthSalaryList (params) {
  return request({
    url: '/api/wx/getEmpMonthSalaryList',
    method: 'get',
    params
  })
}
// 获取某个月份的工资主记录（可能一个月有多笔记录）
export function getEmpMonthSalaryMaster (params) {
  return request({
    url: '/api/wx/getEmpMonthSalaryMaster',
    method: 'get',
    params
  })
}
// 全部签收某个月的所有工资记录接口（可能一个月有多笔记录）
export function signMonthSalaryAll (data) {
  return request({
    url: '/api/wx/signMonthSalaryAll',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}
// 全部签收某个月的所有工资记录接口（可能一个月有多笔记录）json
export function signMonthSalaryAllRawJson (data) {
  return request({
    url: '/api/wx/signMonthSalaryAllRawJson',
    method: 'post',
    data: data
  })
}
// 获取某个月单笔工资详细信息
export function getEmpMonthSalaryDetail (params) {
  return request({
    url: '/api/wx/getEmpMonthSalaryDetail',
    method: 'get',
    params
  })
}
// 签收某个月单笔工资接口
export function signMonthSalarySgl (data) {
  return request({
    url: '/api/wx/signMonthSalarySgl',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 签收某个月单笔工资接口Josn
export function signMonthSalarySglRawJson (data) {
  return request({
    url: '/api/wx/signMonthSalarySglRawJson',
    method: 'post',
    data: data
  })
}

// 6.返回【我的】页签按钮列表接口
export function getWxMyBtnList (params) {
  return request({
    url: '/api/wx/getWxMyBtnList',
    method: 'get',
    params
  })
}
// 7.修改工资查询密码
export function changeSalaryPwd (params) {
  return request({
    url: '/api/login/changeSalaryPwd',
    method: 'get',
    params
  })
}
// 8.重置工资查询密码
export function setSalaryPwd (params) {
  return request({
    url: '/api/login/setSalaryPwd',
    method: 'get',
    params
  })
}

// 根据工号获取用户的其它信息
export function getEmpInfo (params) {
  return request({
    url: '/api/wx/getEmpInfo',
    method: 'get',
    params
  })
}

export function getEmpInfoByEmpId (params) {
  return request({
    url: '/api/baseform/getEmpInfoByEmpId',
    method: 'get',
    params
  })
}

// 获取树层级
export function getSelTreeLevel (params) {
  return request({
    url: '/api/wx/getSelTreeLevel',
    method: 'get',
    params
  })
}

// 获取“我的证件”列表
export function getMyCertList (params) {
  return request({
    url: '/api/wx/getMyCertList',
    method: 'get',
    params
  })
}

// 获取“我的证件”详细信息
export function getMyCertInfo (params) {
  return request({
    url: '/api/wx/getMyCertInfo',
    method: 'get',
    params
  })
}

// 获取“我的证件”附件列表
export function getModuleAttFileList (params) {
  return request({
    url: '/api/wx/getModuleAttFileList',
    method: 'get',
    params
  })
}

// 保存一个证件详细信息接口
export function saveMyCertInfo (data) {
  return request({
    url: '/api/wx/saveMyCertInfo',
    method: 'post',
    data
  })
}

// 新增一个证件详细信息接口
export function addMyCertInfo (data) {
  return request({
    url: '/api/wx/addMyCertInfo',
    method: 'post',
    data
  })
}

// base64图片上传
export function upLoadBase64File (data) {
  return request({
    url: '/api/wx/upLoadBase64File',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// base64图片上传json模式
export function upLoadBase64FileJson (data) {
  return request({
    url: '/api/wx/upLoadBase64FileJson',
    method: 'post',
    data
  })
}

// 删除已上传图片
export function deleteFile (data) {
  return request({
    url: '/api/wx/deleteFile',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 绑定云之家
export function BindingYzj (params) {
  return request({
    url: '/api/wx/BindingYzj',
    method: 'get',
    params
  })
}

// 获取模块列表
export function getModuleList (params) {
  return request({
    url: '/api/wxcheck/getModuleList',
    method: 'get',
    params: params
  })
}

// 获取审批流列表
export function GetWorkflowInfo (params) {
  return request({
    url: '/api/wxcheck/getWorkFlowList',
    method: 'get',
    params: params
  })
}
// 获取待我审批分类汇总列表接口
export function getActionWorkFlowClass (params) {
  return request({
    url: '/api/wxcheck/getActionWorkFlowClass',
    method: 'get',
    params: params
  })
}
// 获取我发起单据的分类汇总列表接口
export function getMyWorkFlow (params) {
  return request({
    url: '/api/wxcheck/getMyWorkFlow',
    method: 'get',
    params: params
  })
}
// 获取我已审批的分类汇总列表接口
export function getMyCheckWorkFlow (params) {
  return request({
    url: '/api/wxcheck/getMyCheckWorkFlow',
    method: 'get',
    params: params
  })
}
// 获取被撤销的分类汇总列表接口
export function getReturnWorkFlowCount (params) {
  return request({
    url: '/api/wxcheck/getReturnWorkFlowCount',
    method: 'get',
    params: params
  })
}

// 获取未提交单据的分类汇总接口
export function getNotPostClass (params) {
  return request({
    url: '/api/wxcheck/getNotPostClass',
    method: 'get',
    params: params
  })
}
// 获取某个流程的待我审批单据列表接口
export function getActionWorkFlowOfOneClass (params) {
  return request({
    url: '/api/wxcheck/getActionWorkFlowOfOneClass',
    method: 'get',
    params: params
  })
}
// 获取带我审批单据列表
export function getActionWorkFlow (params) {
  return request({
    url: '/api/wxcheck/getActionWorkFlow',
    method: 'get',
    params: params
  })
}
// 获取某个流程我发起单据的明细单据列表接口
export function getOneClassOfMyWf (params) {
  return request({
    url: '/api/wxcheck/getOneClassOfMyWf',
    method: 'get',
    params: params
  })
}
// 获取某个流程我已审批的明细单据列表接口
export function getOneClassOfMyCheckWf (params) {
  return request({
    url: '/api/wxcheck/getOneClassOfMyCheckWf',
    method: 'get',
    params: params
  })
}
// 获取某个流程被撤销的明细单据列表接口
export function getReturnWorkFlowOneClass (params) {
  return request({
    url: '/api/wxcheck/getReturnWorkFlowOneClass',
    method: 'get',
    params: params
  })
}
// 获取某个流程的未提交单据列表接口
export function getNotPostList (params) {
  return request({
    url: '/api/wxcheck/getNotPostList',
    method: 'get',
    params: params
  })
}

// 获取模块配置信息接口
export function getInfo (params) {
  return request({
    url: '/api/wx/getBaseFormInfo',
    method: 'get',
    params: params
  })
}

// 获取表单控件构造信息
export function GetFormFieldDetail (data) {
  return request({
    url: '/api/wx/getFormFieldDetail',
    method: 'post',
    data
  })
}

//  获取表单记录接口
export function GetFormRecordData (data) {
  return request({
    url: '/api/wx/getFormRecordData',
    method: 'post',
    data
  })
}

//  查询数据接口
export function queryGridData (data) {
  // let wxToken = sessionStorage.getItem('token')
  // data.moduleqyfilter = encrypt(wxToken, data.moduleqyfilter)
  // data.specfilter = encrypt(wxToken, data.specfilter)
  // data.querysql = encrypt(wxToken, data.querysql)
  // data.fastquerylist = encrypt(wxToken, data.fastquerylist)
  return request({
    url: '/api/wx/queryGridData',
    method: 'post',
    data
  })
}

// 获取员工某一天的订餐信息
export function getEmpDayBookInfo (params) {
  return request({
    url: '/api/m1013/getEmpDayBookInfo',
    method: 'get',
    params: params
  })
}

// 2.	保存员工某一天的订餐信息；
export function saveEmpDayBookInfo (data) {
  return request({
    url: '/api/m1013/saveEmpDayBookInfo',
    method: 'post',
    // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 2.	进入订餐页面判断是否需要支付的接口；
export function checkEmpIsWxPay (params) {
  return request({
    url: '/api/m1013/checkEmpIsWxPay',
    method: 'get',
    params: params
  })
}

// 获取工资签收相关参数
export function getSalarySignParam (params) {
  return request({
    url: '/api/wx/getSalarySignParam',
    method: 'get',
    params: params
  })
}

// 同意工资签收申请协议接口
export function agreePaySignProtocol (params) {
  return request({
    url: '/api/wx/agreePaySignProtocol',
    method: 'get',
    params: params
  })
}

// 保存工资签收的反馈意见接口
export function savePaySignFeedBack (data) {
  return request({
    url: '/api/wx/savePaySignFeedBack',
    method: 'post',
    // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 获取上一次工资签收的反馈内容接口
export function getLastPaySignFeedBackInfo (params) {
  return request({
    url: '/api/wx/getLastPaySignFeedBackInfo',
    method: 'get',
    params: params
  })
}

// 获取查询页面相关配置信息接口
export function getHrQueryPageInfo (params) {
  return request({
    url: '/api/wx/getHrQueryPageInfo',
    method: 'get',
    params: params
  })
}

// 根据参数查询HR各页面所需记录接口
export function getHrQueryRecord (params) {
  return request({
    url: '/api/wx/getHrQueryRecord',
    method: 'get',
    params: params
  })
}

// 上传文件
export function upLoadFile (data) {
  return request({
    url: '/api/wx/upLoadFile',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data: data
  })
}
export function saveBarCodeRecord (params) {
  return request({
    url: '/api/wx/saveBarCodeRecord',
    method: 'get',
    params: params
  })
}

// 12.1获取密码策略信息
export function getPwdPolicy (data) {
  return request({
    url: '/api/m9908/getPwdPolicy',
    method: 'get',
    params: data
  })
}
// 获取记录附件文件夹接口
export function getAttachFilePath (data) {
  return request({
    url: '/api/baseform/getAttachFilePath',
    method: 'get',
    params: data
  })
}
// 获取自拍照文件夹接口
export function getCapPicPath (data) {
  return request({
    url: '/api/baseform/getCapPicPath',
    method: 'get',
    params: data
  })
}
// 获取证件照文件夹接口
export function getEmpPicPath (data) {
  return request({
    url: '/api/baseform/getEmpPicPath',
    method: 'get',
    params: data
  })
}
// 获取审批流附件文件夹接口
export function getFormFilePath (data) {
  return request({
    url: '/api/baseform/getFormFilePath',
    method: 'get',
    params: data
  })
}

// 获取页面详细信息相关接口 --自定义预入职
export function getPreEntryOfferCheckInfo (data) {
  return request({
    url: '/api/wx/getPreEntryOfferCheckInfo',
    method: 'get',
    params: data
  })
}

export function GetFormFieldExitValue (data) {
  // 表格数据带出接口
  return request({
    url: '/api/wx/getFormFieldExitValue',
    method: 'post',
    data
  })
}

export function GetBeforeActionList (data) {
  // 保存数据接口1//删除1
  return request({
    url: '/api/wx/getBeforeActionList',
    method: 'get',
    params: data
  })
}

export function ProcessBeforeAction (data) {
  // 保存数据接口2//删除2
  return request({
    url: '/api/wx/processBeforeAction',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

export function PostModuleRecord (data) {
  // 保存数据接口3
  return request({
    url: '/api/wx/postModuleRecord',
    method: 'post',
    data
  })
}

export function ProcessAfterAction (data) {
  // 保存数据接口3
  return request({
    url: '/api/wx/processAfterAction',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

//一键阅读所有未读的消息接口
export function batchReadMsg (data) {
  return request({
    url: '/api/wx/batchReadMsg',
    method: 'get',
    params: data
  })
}

//获取某个月的订餐信息接口
export function getSimpleBookMonthInfo (data) {
  return request({
    url: '/api/wx/getSimpleBookMonthInfo',
    method: 'get',
    params: data
  })
}
// 保存某个月的订餐信息接口
export function saveSimpleBookMonthInfo (data) {
  return request({
    url: '/api/wx/saveSimpleBookMonthInfo',
    method: 'post',
    data
  })
}

// 扫码消费或刷卡消费接口
export function processWxScanMeal (params) {
  return request({
    url: '/api/wx/processWxScanMeal',
    method: 'get',
    params
  })
}

// 获取考勤签收相关参数
export function getKqSignParam (params) {
  return request({
    url: '/api/wx/getKqSignParam',
    method: 'get',
    params
  })
}

// 同意考勤签收申请协议接口
export function agreeKqSignProtocol (params) {
  return request({
    url: '/api/wx/agreeKqSignProtocol',
    method: 'get',
    params
  })
}

// 获取上一次考勤签收的反馈内容接口
export function getLastKqSignFeedBackInfo (params) {
  return request({
    url: '/api/wx/getLastKqSignFeedBackInfo',
    method: 'get',
    params
  })
}

// 保存考勤签收的反馈意见接口
export function saveKqSignFeedBack (data) {
  return request({
    url: '/api/wx/saveKqSignFeedBack',
    method: 'post',
    data
  })
}

// 保存考勤签收签字图片接口
export function signMonthKqSgl (data) {
  return request({
    url: '/api/wx/signMonthKqSgl',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}
// 测试签名bug接口
export function ybePostFileStr (data) {
  return request({
    url: '/api/wx/ybePostFileStr',
    method: 'post',
    data
  })
}

export function signMonthKqSglRawJson (data) {
  return request({
    url: '/api/wx/signMonthKqSglRawJson',
    method: 'post',
    data
  })
}

//打开某个月份的考勤月报已查阅接口
export function openKqMonthReport (params) {
  return request({
    url: '/api/wx/openKqMonthReport',
    method: 'get',
    params
  })
}

//打开某个月份的工资月报已查阅接口
export function openSalaryMonthReport (params) {
  return request({
    url: '/api/wx/openSalaryMonthReport',
    method: 'get',
    params
  })
}

// 获取扫码入职地址
export function getScanEntryUrl () {
  return request({
    url: '/api/baseform/getScanEntryUrl',
    method: 'get'
  })
}

// 根据guid获取单点登录数据接口
export function getResumeSsoInfo (data) {
  return request({
    url: '/api/wx/getResumeSsoInfo',
    method: 'get',
    params: data
  })
}
// 根据guid获取单点登录数据接口2
export function getCommSsoUserInfo (data) {
  return request({
    url: '/api/wx/getCommSsoUserInfo',
    method: 'get',
    params: data
  })
}

// 点击外部系统链接时获取其详细信息接口
export function getUserSsoInfo (data) {
  const { autoid, userid } = data
  return request({
    url: '/api/workbench/getUserSsoInfo',
    method: 'get',
    params: { autoid, userid }
  })
}

// 获取工作台新闻分类接口
export function getWorkBenchNewsType (data) {
  return request({
    url: '/api/workbench/getWorkBenchNewsType',
    method: 'get',
    params: data
  })
}

// 获取工作台某个分类的新闻列表接口
export function getWorkBenchNewsList (data) {
  return request({
    url: '/api/workbench/getWorkBenchNewsList',
    method: 'get',
    params: data
  })
}

// 待我审批分类接口
export function GetActionWorkflowZX (data) {
  return request(
    {
      url: '/Server/GetActionWorkflowZX',
      method: 'get',
      params: data
    },
    cloundserver || ''
  )
}
// 我发起的分类接口
export function GetMyWorkflow (data) {
  return request(
    {
      url: '/Server/GetMyWorkflow',
      method: 'get',
      params: data
    },
    cloundserver || ''
  )
}
// 我已审批
export function GetMyCheckWorkflow (data) {
  return request(
    {
      url: '/Server/GetMyCheckWorkflow',
      method: 'get',
      params: data
    },
    cloundserver || ''
  )
}
// 被撤销的
export function GetReturnWorkflow (data) {
  return request(
    {
      url: '/Server/GetReturnWorkflow',
      method: 'get',
      params: data
    },
    cloundserver || ''
  )
}
// 获取某个模块的附件
export function getFile (data) {
  return request(
    {
      url: '/Server/getFile',
      method: 'get',
      params: data
    },
    cloundserver || ''
  )
}

// 获取上一次考勤签收图片地址接口
export function getKqLastSignPicUrl (data) {
  return request({
    url: '/api/wx/getKqLastSignPicUrl',
    method: 'get',
    params: data
  })
}

// //获取上一次工资签收图片地址接口
export function getSalaryLastSignPicUrl (data) {
  return request({
    url: '/api/wx/getSalaryLastSignPicUrl',
    method: 'get',
    params: data
  })
}

// 获取某天厂车的预定信息
export function getCarBookDayInfo (data) {
  return request({
    url: '/api/wx/getCarBookDayInfo',
    method: 'get',
    params: data
  })
}

// 获取厂车线路明细接口
export function getCarLineInfo (data) {
  return request({
    url: '/api/wx/getCarLineInfo',
    method: 'get',
    params: data
  })
}
// 保存厂车预定信息
export function saveCarBookDayInfo (data) {
  return request({
    url: '/api/wx/saveCarBookDayInfo',
    method: 'get',
    params: data
  })
}

// 12.	审批推送接口
export function pushwxmsg (data) {
  data.moduleqyfilter = null
  data.specfilter = null
  data.querysql = null
  data.optionfilter = []
  data.isall = false
  return request({
    url: '/api/m9913/pushwxmsg',
    method: 'post',
    data: data
  })
}
// 提交未提交单据
export function postWorkFlowList (data) {
  data.moduleqyfilter = null
  data.specfilter = null
  data.querysql = null
  data.optionfilter = []
  data.isall = false
  return request({
    url: '/api/m9913/postWorkFlowList',
    method: 'post',
    data: data
  })
}
// 3 提交单据接口（支持批量）
export function postWorkFlowListCheck (params) {

  const {
    guid,
    formautoid,
    mkeyname,
    mtablename,
    mviewname,
    isall,
    autoidlist,
    moduleqyfilter,
    specfilter,
    querysql,
    username,
    checkmemo,
    queryindex,
    optionfilter,
    flag
  } = params
  return request({
    url: '/api/m9913/postWorkFlowList',
    method: 'post',
    data: {
      guid,
      formautoid,
      mkeyname,
      mtablename,
      mviewname,
      isall,
      autoidlist,
      moduleqyfilter,
      specfilter,
      querysql,
      username,
      checkmemo,
      queryindex,
      optionfilter,
      flag
    }
  })
}
// 4 否决单据接口（支持批量）
export function disPostWorkFlowList (params) {
  const {
    guid,
    formautoid,
    mkeyname,
    mtablename,
    mviewname,
    isall,
    autoidlist,
    moduleqyfilter,
    specfilter,
    querysql,
    username,
    checkmemo,
    queryindex,
    optionfilter,
    flag
  } = params
  return request({
    url: '/api/m9913/disPostWorkFlowList',
    method: 'post',
    data: {
      guid,
      formautoid,
      mkeyname,
      mtablename,
      mviewname,
      isall,
      autoidlist,
      moduleqyfilter,
      specfilter,
      querysql,
      username,
      checkmemo,
      queryindex,
      optionfilter,
      flag
    }
  })
}

// 删除未提交单据
export function deleteWorkFlowList (data) {
  data.moduleqyfilter = null
  data.specfilter = null
  data.querysql = null
  data.optionfilter = []
  data.isall = false
  return request({
    url: '/api/m9913/deleteWorkFlowList',
    method: 'post',
    data: data
  })
}

// 获取某个月份的企业日历明细接口
export function getEmpCalendarInfo (data) {
  return request({
    url: '/api/wx/getEmpCalendarInfo',
    method: 'get',
    params: data
  })
}

// 获取员工的厂车二维码信息
export function getEmpBarCodeInfo (data) {
  return request({
    url: '/api/wx/getEmpBarCodeInfo',
    method: 'get',
    params: data
  })
}

// 获取V8ApiToken
export function getDynV8ApiToken () {
  return request({
    url: '/api/login/getDynV8ApiToken',
    method: 'get'
  })
}

// 获取aes解密字符串
export function getdecode (data, v8apitoken) {
  return request({
    url: '/aes/getdecode',
    method: 'get',
    params: data,
    headers: { v8apitoken: v8apitoken }
  })
}
// 自定义接口get
export function getReq (data, v8apitoken) {
  return request({
    url: '/v8api/comm/getReq',
    method: 'get',
    params: data,
    headers: { v8apitoken: v8apitoken }
  })
}
// 获取“抄送单据”分类信息
export function getCsListForClass (data) {
  return request({
    url: '/api/wxcheck/getCsListForClass',
    method: 'get',
    params: data
  })
}

// 某个流程的抄送明细
export function getCsWorkFlowOfOneClass (data) {
  return request({
    url: '/api/wxcheck/getCsWorkFlowOfOneClass',
    method: 'get',
    params: data
  })
}

export function updateCheckListStatus (params) {
  return request({
    url: '/api/workbench/updateCheckListStatus',
    method: 'get',
    params: params
  })
}

// 自定义接口form-data
export function postReqFormData (data, v8apitoken) {
  return request({
    url: '/v8api/comm/postReqFormData',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
      v8apitoken: v8apitoken
    }
  })
}

export function getWaterMarkTxt (params) {
  return request({
    url: '/api/wx/getWaterMarkTxt',
    method: 'get',
    params: params
  })
}

// 获取通用查询员工弹窗的表格列信息接口
export function getEmpSelectGridFieldList (params) {
  return request({
    url: '/api/query/getEmpSelectGridFieldList',
    method: 'get',
    params: params
  })
}

// 6.	第三方跳转到V8 登录
export function getV8SsoInfo (data) {
  return request({
    url: '/api/login/getV8SsoInfo',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    data
  })
}

// 获取某段时间所有人员的排班记录接口
export function getWxMonthShiftInfo (params) {
  return request({
    url: '/api/wx/getWxMonthShiftInfo',
    method: 'get',
    params: params
  })
}

// 保存指定人员指定日期班次接口
export function saveWxMonthShiftInfo (data) {
  return request({
    url: '/api/wx/saveWxMonthShiftInfo',
    method: 'post',
    data: data
  })
}

// 获取指定月份某个楼层所有宿舍抄数记录
export function getWxMonthDormCostInput (params) {
  return request({
    url: '/api/wx/getWxMonthDormCostInput',
    method: 'get',
    params: params
  })
}

// 获取指定月份某个宿舍的抄数记录
export function getWxFixDormCost (params) {
  return request({
    url: '/api/wx/getWxFixDormCost',
    method: 'get',
    params: params
  })
}

// 保存指定人员指定日期班次接口
export function saveWxFixDormCost (data) {
  return request({
    url: '/api/wx/saveWxFixDormCost',
    method: 'post',
    data: data
  })
}

// 查看员工任职资格标准信息接口
export function getEmpStdEmployeMentInfo (data) {
  return request({
    url: "/api/workbench/getEmpStdEmployeMentInfo",
    method: "post",
    data: data
  });
}