import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import Cookies from 'js-cookie'

Vue.use(VueI18n)

const messages = {
  lang0: {
    ...pro_lang0
  },
  lang1: {
    ...pro_lang1
  },
  lang2: {
    ...pro_lang2
  },
  lang3: {
    ...pro_lang3
  },
  lang4: {
    ...pro_lang4
  },
  lang5: {
    ...pro_lang5
  },
  lang6: {
    ...pro_lang6
  },
  lang7: {
    ...pro_lang7
  },
  lang8: {
    ...pro_lang8
  },
  lang9: {
    ...pro_lang9
  },
  lang10: {
    ...pro_lang10
  },
  lang11: {
    ...pro_lang11
  },
  lang12: {
    ...pro_lang12
  },
  lang13: {
    ...pro_lang13
  },
  lang14: {
    ...pro_lang14
  },
  lang15: {
    ...pro_lang15
  },
  lang16: {
    ...pro_lang16
  },
  lang17: {
    ...pro_lang17
  },
  lang18: {
    ...pro_lang18
  // },
  // lang19: {
  //   ...pro_lang19
  // },
  // lang20: {
  //   ...pro_lang20
  }
};
export function getLanguage() {
  const chooseLanguage = localStorage.getItem("language")
    ? "lang" + localStorage.getItem("language")
    : "lang0";
  console.log(localStorage.getItem("language"), "111111111");
  
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return "lang0";
}
console.log(1111111, getLanguage())
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
