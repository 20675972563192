<template>
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { removeWatermark, setWaterMark } from '@/utils/watermark'
export default {
  name: 'App',
  created () {
    // 用于钉钉jssdk鉴权，必修记录首次加载的地址
    window.globalurls = window.location.href
    const obj = localStorage.getItem("sysShuiYin");
    if(obj && JSON.parse(obj)){
      const nobj = JSON.parse(obj)
      if(nobj.show_watermark == 1){
        setWaterMark(
          nobj.watermark_line1,
          nobj.watermark_line2,
          nobj.watermark_line3
        )
      }
    }
  }
}
</script>
<style lang="less">
#app {
  height: 100%;
  overflow: auto;
}
.win_tree_popup{
  z-index: 999999 !important;
}
.win_tree_popup_overlay{
  z-index: 99999 !important;
}
// #v8_qrcode {
//   width: 4.52rem !important;
//   height: 4.52rem !important;
//   margin: 0 auto !important;
//   img {
//     width: 4.52rem !important;
//     height: 4.52rem !important;
//   }
//   canvas {
//     width: 4.52rem !important;
//     height: 4.52rem !important;
//   }
// }
.van-toast {
  width: 50% !important;
}
.MYNewsDetails {
  img {
    height: auto !important;
  }
  p {
    line-height: 1.5 !important;
    margin: 0 !important;
  }
}
.mspj-super-class {
  margin-bottom: 30px;
  .van-cell__title {
    text-align: left !important;
  }
  .van-collapse-item__content {
    text-align: left !important;
  }
}

.wddqwqwdwdqw {
  color: red !important;
}
.hideNfLLL{
  .van-picker__columns{
    .van-picker-column{
      &:nth-child(2){
        display: none !important;
      }
    }
  }
}
.windowImagePreviewClassName{
  .van-image__img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}
.van-toast--unclickable *{
  pointer-events: all !important;
}
</style>
