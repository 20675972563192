const OpenIdKey = 'OpenId'
const YzjIdKey = 'YzjId'

export function getOpenId() {
  if (process.env.NODE_ENV === 'production') {
    return sessionStorage.getItem(OpenIdKey)
  } else {
    return ''
  }
}

export function setOpenId(openId) {
  return sessionStorage.setItem(OpenIdKey, openId)
}

export function removeOpenId() {
  return sessionStorage.removeItem(OpenIdKey)
}

export function getYzjOpenId() {
  if (process.env.NODE_ENV === 'production') {
    return sessionStorage.getItem(YzjIdKey)
  } else {
    return '22222'
  }
}

export function setYzjOpenId(YzjId) {
  return sessionStorage.setItem(YzjIdKey, YzjId)
}

export function removeYzjOpenId() {
  return sessionStorage.removeItem(YzjIdKey)
}
